var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "page-blacklistsearch" },
    [
      _c("tev-navbar", { attrs: { title: "Coding Documents" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "text-xs-center": "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-4": "" } },
                                [
                                  _vm._v(
                                    "Please upload all PDF documents for coding in order. You can change the order by dragging the documents."
                                  ),
                                ]
                              ),
                              !_vm.codingDocs.length
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pb-4": "" } },
                                    [
                                      !_vm.isUploading.annual_report
                                        ? _c("UploadButton", {
                                            attrs: {
                                              depressed: "",
                                              title: "Upload",
                                              multiple: false,
                                              done: _vm.uploadCodingDoc,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.isUploading.annual_report
                                        ? _c("v-btn", {
                                            class: { "blue--text": true },
                                            attrs: {
                                              large: "",
                                              outline: "",
                                              color: "primary",
                                              loading: true,
                                              disabled: true,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group",
                                      attrs: {
                                        list: _vm.uploadedDocs,
                                        disabled: false,
                                        "ghost-class": "ghost",
                                        move: _vm.checkMove,
                                      },
                                      on: {
                                        start: function ($event) {
                                          _vm.dragging = true
                                        },
                                        end: function ($event) {
                                          _vm.dragging = false
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.uploadedDocs,
                                      function (element) {
                                        return _c(
                                          "div",
                                          {
                                            key: element.name,
                                            staticClass: "list-group-item",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(element.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "pt-4": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depreesed: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goCoding()
                                        },
                                      },
                                    },
                                    [_vm._v("Coding")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depreesed: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goPreview()
                                        },
                                      },
                                    },
                                    [_vm._v("Preview")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }