<template>
  <v-app class="page-blacklistsearch">
    <tev-navbar title="Coding Documents" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <transition name="fade">
              <v-layout row wrap text-xs-center>
                <v-flex
                  xs12
                  mb-4
                >Please upload all PDF documents for coding in order. You can change the order by dragging the documents.</v-flex>

                <v-flex xs12 pb-4 v-if="!codingDocs.length">
                  <UploadButton
                    depressed
                    v-bind:title="'Upload'"
                    v-bind:multiple="false"
                    v-bind:done="uploadCodingDoc"
                    v-if="!isUploading.annual_report"
                  ></UploadButton>
                  <v-btn
                    large
                    outline
                    color="primary"
                    :loading="true"
                    v-bind:class="{'blue--text': true}"
                    :disabled="true"
                    v-if="isUploading.annual_report"
                  ></v-btn>
                </v-flex>
                <v-flex xs12>
                  <draggable
                    :list="uploadedDocs"
                    :disabled="false"
                    class="list-group"
                    ghost-class="ghost"
                    :move="checkMove"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <div
                      class="list-group-item"
                      v-for="element in uploadedDocs"
                      :key="element.name"
                    >
                      {{ element.name }}
                    </div>
                  </draggable>
                </v-flex>
                <v-flex pt-4>
                  <v-btn depreesed color="primary" @click="goCoding()">Coding</v-btn>
                  <v-btn depreesed color="primary" @click="goPreview()">Preview</v-btn>
                </v-flex>
              </v-layout>
            </transition>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import UploadButton from "../../components/UploadButton";
import checksum from "../../libs/checksum";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";

export default {
  data() {
    return {
      showDialog: false,
      isLoading: false,
      isUploading: {
        annual_report: false,
        incorporation: false
      },
      isAutoFill: false,
      pdfMimeType: "application/pdf",
      view: null,

      uploadedDocs: [],
      codingDocs: [],

      geetsiApiUrl: "https://clientev.geetsi.es/api"
    };
  },
  components: {
    UploadButton,
    draggable
  },
  computed: {
    /*
    ...mapGetters({
      currentApp: "currentApp"
    }),*/
    /*
    propertyId() {
      return this.currentApp.propertyId;
    },
    onboardingDocuments() {
      return this.currentApp.onboardingDocuments || [];
    },
    einNumber() {
      return this.currentApp.einNumber;
    },
    isFloridaCo() {
      return this.currentApp.businessOrigin == "FL";
    },
    codingDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "codingDocs"
      );
    },
    incorporationDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "incorporation"
      );
    },
    valid() {
      return this.annualReportDocs.length && this.incorporationDocs.length;
    }*/
    /*
    codingDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "codingDocs"
      );
    },    */
  },
  watch: {
    showDialog(val) {
      if (!val) {
        const _this = this;
        setTimeout(function() {
          _this.view = null;
        }, 300);
      }
    }
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    loadProperty() {
      const _this = this;
      this.$http
        .get("/manager/properties/pending/" + this.propertyId)
        .then(response => {
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](response.data);
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },

    uploadDoc(file, description) {
      const _this = this;
      _this.isUploading[description] = true;
      _this.$http
        .post("/manager/uploadDocument", {
          propertyId: _this.propertyId,
          description: description,
          filename: file["name"],
          fileMimeType: file["type"],
          fileData: file["data"]
        })
        .then(() => {
          _this.loadProperty();
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => {
          _this.isUploading[description] = false;
        });
    },
    uploadCodingDoc(file) {
      this.uploadedDocs.push({name:file.name});
      //console.log('uploadCodingDoc ' + JSON.stringify(file))
      //console.log('uploadCodingDoc ' + JSON.stringify(JSON.parse(file)))
      //this.uploadDoc(file, "coding");
    },
    checkMove: function(e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    viewDoc(id) {
      this.view = this.onboardingDocuments.find(doc => doc.id == id);
      this.showDialog = true;
    },
    removeDoc(id) {
      const _this = this;
      this.$http
        .delete("/manager/documents/" + id)
        .then(() => {
          _this.loadProperty();
          _this.isAutoFill = false;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    submitForm() {
      if (this.annualReportDocs.length && this.incorporationDocs.length) {
        this.$events.$emit("success", {
          step: this.step
        });
      } else {
        this.alertError("Please upload the required documents.");
      }
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    },
    checkDocs() {
      if (
        this.einNumber &&
        this.isFloridaCo &&
        (!this.annualReportDocs.length || !this.incorporationDocs.length)
      ) {
        this.getSunbizDocs();
      }
    },
    goCoding() {
      this.$router.push("/coding/coding");
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    },
    onboardingDocumentsChanged(e) {
      this.checkDocs();
    }
  },
  mounted() {
    console.log("mounted " + this.$route.params.id);

    /*
    this.fillForm();
    this.checkDocs();
    */
  }
};
</script>
<style>
.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    width: 70%!important;
    margin-left: 15%;
}

.list-group-item {
    cursor: move;
}

</style>